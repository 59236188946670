import React from "react"
import PlayCircleIcon from "images/components/daily_clip_collection/play_circle_icon.png"
import Clip from "./Clip";

class DailyClipCollection extends React.Component {
    constructor(props, _railsContext) {
        super(props);
    }

    render () {
        const clips = this.props.clips
        const date = this.props.date

        return (
            <React.Fragment>
                <div className="daily-clip-collection--main-container">
                    <div className="daily-clip-collection--date-label-container">
                        <img className="daily-clip-collection--date-label-play-icon" src={PlayCircleIcon} />
                        <div className="daily-clip-collection--date-label-date-text">{date}</div>
                    </div>
                    {clips.map(function(clip){ return <Clip key="clip" clip={clip}/> })}

                </div>
            </React.Fragment>
        );
    }
}

export default DailyClipCollection
