import React from "react"
import PropTypes from "prop-types";
import Header from "./Header";
import BucketIcon from "images/components/page_title/bucket_icon.png"
import CogIcon from "images/components/page_title/cog_icon.png"
import GithubIcon from "images/components/page_title/github_icon.png"

class PageTitle extends React.Component {
  render () {
    return (
      <React.Fragment>
          <div className="page-title--container">
              <div className="page-title--menu-item page-title--blue-bar" />
              <div className="page-title--menu-item page-title--text">{this.props.title}</div>
              <div className="page-title--menu-item page-title--icon-container">
                  <img onClick={this.openGithub} className="page-title--icon-image" src={GithubIcon}/>
              </div>
              <div className="page-title--menu-item page-title--icon-container">
                  <img onClick={this.openBucket} className="page-title--icon-image" src={BucketIcon}/>
              </div>
              <div className="page-title--menu-item page-title--icon-container">
                  <img onClick={this.openSettings} className="page-title--icon-image" src={CogIcon}/>
              </div>
          </div>
      </React.Fragment>
    );
  }

    openGithub () {
        console.log("clicked the github button")
    }

    openBucket () {
        console.log("clicked the bucket button")
    }

    openSettings () {
        console.log("clicked the settings button")
    }
}


Header.propTypes = {
    title: PropTypes.string
};

export default PageTitle
