import React from "react"
import LogoImage from "images/components/header/logo.png"
import UserIconImage from "images/components/header/user_icon.png"

class Header extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className="header--full-width-background">
            <div className="header--interactive-content-container">
              <div className="header--menu-container">
                  <img className="header--logo" src={LogoImage}/>
                  <div className="header--menu-item header--menu-link"><a href="/clips/live">Live</a></div>
                  <div className="header--menu-item header-menu-item-divider"/>
                  <div className="header--menu-item header--menu-link"><a href="/clips/recent">Recent</a></div>
                  <div className="header--menu-item header-menu-item-divider"/>
                  <div className="header--menu-item header--menu-link"><a href="/clips/archived">Archived</a></div>
                  <div className="header--menu-item header-menu-item-divider"/>
                  <div className="header--menu-item header--menu-link"><a href="/clips/flagged">Flagged</a></div>
                  <div className="header--menu-item header-menu-item-divider"/>
                  <div className="header--menu-item header--menu-link"><a href="#">Cameras</a></div>
                  <div className="header--menu-item header-menu-item-divider"/>
                  <div className="header--menu-item">
                      <a href="#"><img className="header--user-icon" src={UserIconImage}/></a>
                  </div>
                  <div className="header--menu-item header-menu-item-divider"/>
              </div>
            </div>
        </div>
        <div className="header--full-width-line" />
      </React.Fragment>
    );
  }
}

export default Header
