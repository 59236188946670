import React from "react"
import ClipOperations from "./ClipOperations";

class Clip extends React.Component {
    clip = this.props.clip;
    videoLink = null

    constructor(props, _railsContext) {
        super(props);

        this.state = {
            loadingDependencies : false
        }
    }

    componentDidMount() {
        fetch(`http://0.0.0.0:3000/clips/presigned_url?bucket=${this.clip.bucket_name}&s3_key=${this.clip.s3_key}`)
            .then((response) => {
                return response.json();
            })
            .then(
                (res) => {
                    this.videoLink = res.presigned_url
                    this.setState({loadingDependencies : true});
                },
            )
    }

    render () {
        const date = new Date(this.clip.recorded_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: true});

        return (
            <React.Fragment>
                <div className="clip--time-label-container">
                    <div className="clip--time-label-text">
                        {date}
                    </div>
                </div>
                <div className="clip--outer-flex-container">
                    <div className="clip--video-panel-container">
                        {this.state.loadingDependencies ? (
                            <video className="clip--video-stream" controls>
                                <source src={this.videoLink} type="video/mp4" />
                            </video>
                        ) : (
                            <div>Loading video</div>
                        )}
                    </div>
                    <ClipOperations clip={this.clip}/>
                </div>
            </React.Fragment>
        );
    }
}

export default Clip
