import React from "react"
import BinIcon from "images/components/clip_operations/bin_icon.png"
import ShareIcon from "images/components/clip_operations/share_icon.png"
import DownloadIcon from "images/components/clip_operations/download_icon.png"
import FlagIcon from "images/components/clip_operations/flag_icon.png"

class ClipOperations extends React.Component {
    clip = this.props.clip;

    constructor(props, _railsContext) {
        super(props);

        this.deleteClip = this.deleteClip.bind(this);
        this.downloadClip = this.downloadClip.bind(this);
        this.shareClip = this.shareClip.bind(this);
        this.flagClip = this.flagClip.bind(this);
    }

    render () {
        return (
            <React.Fragment>
                <div className="clip-operations--container">
                    <div className="clip--icon-wrapper">
                        <img onClick={this.deleteClip} className="clip-operations--icon" src={BinIcon} />
                        <img onClick={this.downloadClip} className="clip-operations--icon" src={DownloadIcon} />
                        <img onClick={this.shareClip} className="clip-operations--icon" src={ShareIcon} />
                        <img onClick={this.flagClip} className="clip-operations--icon" src={FlagIcon} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    deleteClip () {
        console.log(`clicked the delete button for clip: ${this.clip.id}`)
    }

    downloadClip () {
        console.log(`clicked the download button for clip: ${this.clip.id}`)
    }

    shareClip () {
        console.log(`clicked the share button for clip: ${this.clip.id}`)
    }

    flagClip () {
        console.log(`clicked the flag button for clip: ${this.clip.id}`)
    }
}

export default ClipOperations
